import React from "react";
import { MainBanner } from "./MainBanner";
import "../Css/WhatWeBringeThTheTable.css";
import banner from "../Images/Home/1 (7).webp";
import { Link } from "react-router-dom";
import TitleSection from "./TitleSection";
import { BsArrowRight } from "react-icons/bs";
import MarqueeComponent from "../Asserts/MarqueeComponent";
import imageabovequote from '../Imagess/THE STUDIO/image above quote.jpg'
import back from "../Images/Home/Architecture.jpg";
import { Slide } from "react-reveal";
const WhatWeBringeThTheTable = () => {
  window.scrollTo(0, 0);
  return (
    <>
    <div className="container pt-5">
    <img src={imageabovequote} style={{
      height:'350px',
      width:'100%'
    }}/>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-1 col-md-2"></div>
        <div className="col-10 col-md-8" style={{
          backgroundColor:'white',
          marginTop:'-100px',
          boxShadow:'0px 3px 5px gray'
        }}>
       
        <p className="lead mt-4 px-5 text-center ">
        “ARCHITECTURE is the art above all others which achieves a state of platonic grandeur, mathematical order, speculation, the perception of the harmony which lies in emotional relationships. This is the AIM of archietceture.”
        </p>
        <p className="text-center" style={{fontSize:'23px',fontWeight:'bold'}}>- Le Corbusier</p>
        </div>

        <div className="col-1 col-md-2"></div>

      </div>
    </div>
      <section
        style={{
          // backgroundImage:
          //   "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)),url('" +
          //   back +
          //   "')",
          // backgroundRepeat: "no-repeat",
          // backgroundSize: "100% 100%",
          // objectFit: "cover",
          // backgroundAttachment: "fixed",
          // color: "white",
        }}
      >
        {/* <TitleSection
          title="Our Vision"
          // titlePara="Your Dreams, Our Design: Excellence at Every Turn."
        /> */}
        {/* <MainBanner
        title="What We Bringe To the Table"
        imgURL={banner}
        para="Spoin eravida gibh sel velit tuctor aliquet. Aenean dyfi iysda asslicikoi tudin lorem quis bibendum auctor"
      /> */}
        <section>
          <div className="container">
            <div className="row">
              <div className="mt-5">
                <Slide bottom>
                <TitleSection
          title="Our Vision"
          // titlePara="Your Dreams, Our Design: Excellence at Every Turn."
        />
                  <p className=" text-center" style={{textAlign:"justify"}}>
                  {/* At Kreeze Design Studio, we combine creativity, expertise,
                  and a personalized approach to deliver exceptional
                  interior design solutions. Our talented team brings 
                  a wealth of experience, transforming your vision into
                  beautifully curated spaces that reflect your unique 
                  style and needs.We prioritize collaboration, ensuring
                  your ideas are at the forefront of our design process.
                  With a keen eye for detail With a deep understanding of
                  design principles and current trends, we bring innovative 
                  solutions that elevate your space. Our designers are adept 
                  at balancing aesthetics with practicality, ensuring that
                  each element serves a purpose while enhancing the overall
                  beauty of your environment. */}

{/* <b style={{fontSize:'23px',fontWeight:'bold'}}> “ARCHITECTURE is the art above all others which achieves a state of platonic grandeur, mathematical order, speculation, the perception of the harmony which lies in emotional relationships. This is the AIM of archietceture.”</b> <b style={{fontSize:'23px',fontWeight:'bold'}}>- Le Corbusier</b> <br/><br/> */}
At Kreeze Design Studio, we are committed to creating timeless, purpose-driven spaces through the seamless integration of design and functionality. Our goal is not only to inspire through our designs but to enhance everyday living. As we evolve, we aim to transition into a full-service design-build company, leveraging state-of-the-art technology to offer a one-window solution for our clients. In the next decade, we aspire to be at the forefront of design and construction innovation, setting new standards as leaders in the field
                  </p>
                </Slide>
                <div className="main-btn-1">
                  <button className="what-we-bringe-button" id="btn-submit">
                    <span className="what-we-bringe-button-text">
                      View More{" "}
                      <label>
                        {" "}
                        <BsArrowRight />{" "}
                      </label>{" "}
                    </span>
                    <div className="what-we-bringe-button-loader">
                      <div />
                      <div />
                      <div />
                    </div>
                  </button>
                </div>
                {/* <div className='what-main-btn'>
      <Link to="/Project">
      <button className="button" id="btn-submit">
        <span className="button-text">View More</span>
        <div className="button-loader">
          <div />
          <div />
          <div />
        </div>
      </button>
      </Link>
      </div> */}
              </div>
            </div>
          </div>
        </section>
      </section>



      <MarqueeComponent/>

      
    </>
  );
};

export default WhatWeBringeThTheTable;
