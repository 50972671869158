import React, { useEffect, useRef } from "react";

import "./MarqueeComponent.css";
import MCGM_SEAL from '../Imagess/Logos/MCGM_Seal.jpg'
import relcon_logo from '../Imagess/Logos/relcon logo.webp'
import sotefin_logo from '../Imagess/Logos/sotefin-logo.png'
import logoone from '../Imagess/Logos/logoone.png'
import logotwo from '../Imagess/Logos/logotwo.png'
import logothree from '../Imagess/Logos/logothree.jpg'
import logofour from '../Imagess/Logos/logofour.png'
import logofive from '../Imagess/Logos/logofive.png'
import logosix from '../Imagess/Logos/logosix.jpg'
import logoseven from '../Imagess/Logos/logoseven.jpg'
import logoeight from '../Imagess/Logos/logoeight.jpg'
import logonine from '../Imagess/Logos/logonine.png'
import logoten from '../Imagess/Logos/logoten.jpeg'

const MarqueeComponent = () => {
  const imageList = [
    MCGM_SEAL ,
relcon_logo ,
sotefin_logo ,
logoone ,
logotwo ,
logothree ,
logofour ,
logofive,
logosix,
logoseven ,
logoeight ,
logonine ,
logoten 
    
  ];
  return (
    <>
      <section className="associate-partner-sec sectionDiv my-5">
      <div className="py-5">
        <div className="container logos ">
          <div className="logo_items">
            <marquee></marquee>
            {imageList.map((val, index) => (
              <img key={index} src={val} />
            ))}
          </div>
          {/* <div className="logo_items">
            {imageList.map((val, index) => (
              <img key={index} src={val} />
            ))}
          </div> */}
        </div>
        
        </div>
      </section>
    </>
  );
};

export default MarqueeComponent;
