import t2 from "../Imagess/Team/Ar. Priyal Pandya.jpeg";
import t1 from "../Imagess/Team/Ar. Kaiwalya Chitnis.png";
import t3 from "../Imagess/Team/Jignesh Sanghavi.png";


const teamData =[
    {
        teamImg:t2,
        name:'Priyal Pandya',
        designation:["B.Arch", "DesignPartner of Kreeze Design Studio"],
        descpara:"Ar. Priyal Pandya, Design Partner at Kreeze Design Studio and a graduate of L.S. Raheja School of Architecture, is known for her innovative and sustainable design approach. She gained valuable experience at KNS Architects and participated in a workshop with renowned architect Ar. Fernando Menis, enhancing her knowledge of eco-conscious design principles. After completing specialized online courses from Harvard University in sustainable and parametric design, Priyal co-founded Kreeze Design Studio. Her expertise continues to shape the firm's creative and sustainable solutions across residential, commercial, and landscape projects."
    },
    {
        teamImg:t3,
        name:'Jignesh P.Sanghavi',
        designation:["BE(Civil), MCM,MBA,DBM, ","DREAM,Ch.E(I)", "Founder of Krishna Infrasol Pvt.Ltd."],
        descpara:"Jignesh Sanghavi, a partner at Kreeze Design Studio, brings over 33 years of expertise in civil engineering, real estate, and infrastructure development. He has successfully executed large-scale projects, including the MMRDA headquarters and high-end residential developments in Bandra Kurla Complex, totaling over 2 million sq. ft. with a capital value exceeding $1 billion. Currently, he is leading the construction of the Bank of India headquarters in the same area. From 2004 to 2006, Jignesh managed the National Highway project connecting Mumbai Domestic Airport to Mahim Causeway. As Vice President and later Executive Director at Sunteck Realty Ltd., he played a crucial role in growing the company into a $1 billion market capital firm through strategic acquisitions and large-scale developments. In his roles as CEO of Relcon lnfraprojects Ltd. and Director of Relcon Realty Pvt. Ltd.,he oversees projects worth over $100 million, including the NISM campus project, i naugurated by India's Prime Minister. Additionally, Jignesh has ventured into automated parking solutions with the acquisition of Sotefin Parking Pvt. Ltd., driving significant growth since 2017. His extensive experience continues to enhance Kreeze Design Studio's innovative design and construction solutions."
    },
    {
        teamImg:t1,
        name:'Kaiwalya Chitnis',
        designation:["B.Arch", "Director of Kaiwalya Arch Pvt Ltd","Founder of Kreeze Design Studio"],
        descpara:"Ar. Kaiwalya Chitnis, Design Director at Kreeze Design Studio, brings over a decade of expertise in architecture, design, and fa<;ade solutions. Graduating with top honors from the Academy of Architecture, his career has been defined by a commitment to innovative and socially responsible design. Kaiwalya has worked with prestigious firms like Access Architects and XCOOP, where he gained global experience in sustainable housing projects. Since 2016, he has led KAPL, managing high-budget projects and collaborating with renowned architects. In 2017, he founded Kreeze Design Studio, which delivers multidisciplinary architectural and interior design projects across residential, commercial, and urban sectors, with a focus on sustainability and functionality. As Design Director, Kaiwalya continues to push the boundaries of design, delivering visionary solutions that balance aesthetics with practicality.",
    },
]

export default teamData