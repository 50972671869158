import React, { useState } from 'react';
import "../NavBar/Header.css";
import { RiMenu2Fill } from "react-icons/ri";

import ExpertiesData from "../../Asserts/ExpertiesData";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Button, Drawer } from 'antd';
import Logo from "../../Images/logo/Kreeze Design Studio.png";
import { Link } from 'react-router-dom';

const Header = () => {
    const [open, setOpen] = useState(false);
    
    const showDrawer = () => {
        setOpen(true);
    };
    
    const onClose = () => {
        setOpen(false);
    };

    return (
        <>
        
            <Navbar collapseOnSelect expand="lg" style={{position:'sticky'}} className="bg-body-tertiary">
                <Container>
                    <Navbar.Brand className='logofirst'>
                        <Link to='/'>
                        <div className='navbar-Logo-con'>
                            <img src={Logo} alt='Logo' />
                        </div>
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                        <Nav.Link to="/the-studio" aria-controls="responsive-navbar-nav"><Link to='/the-studio'>The Studio</Link></Nav.Link>
                        <Nav.Link to="/experties" aria-controls="responsive-navbar-nav"><Link to='/experties'>Expertise</Link></Nav.Link>
                        <Nav.Link to="/portfolio/all/all" aria-controls="responsive-navbar-nav"><Link to="/portfolio/all/all">Portfolio</Link></Nav.Link>
                        {/* <Nav.Link to="/blog" aria-controls="responsive-navbar-nav"><Link to='/blog'>Blog</Link></Nav.Link> */}
                            {/* <Nav.Link to="/"><Link to='/'>Home</Link></Nav.Link> */}

                            {/* <NavDropdown title="Experties" id="collapsible-nav-dropdown">
                                {ExpertiesData.map((v,i)=>{
                                    return(
                                        <NavDropdown.Item key={i} to={v.slug}>
                                            <Link to={v.slug}>{v.titleName}</Link>
                                        </NavDropdown.Item>
                                    )
                                })}
                            </NavDropdown> */}
                           
                      
                            {/* <Nav.Link to="/design-element"><Link to='design-element'>Design elements</Link></Nav.Link> */}
                            {/* <Nav.Link to="/blog"><Link to="/blog">Blog</Link></Nav.Link> */}
                        <Nav.Link to="/featured" aria-controls="responsive-navbar-nav"><Link to="/featured">Featured</Link></Nav.Link>

                            <Nav.Link to="/careers"><Link to="/careers">Careers</Link></Nav.Link>
                            <Nav.Link to="/contactUs"><Link to="/contactUs">Contact</Link></Nav.Link>
                        </Nav>
                        {/* <Nav>
                            <div className='NavBar-Side-Drawer-con'>
                                <div className='NavBar-Side-Drawer' onClick={showDrawer}>
                                    <RiMenu2Fill />
                                </div>
                            </div>
                        </Nav> */}
                    </Navbar.Collapse>
                    <Navbar.Brand className='logosecond' >
                        <Link to='/'>
                        <div className='navbar-Logo-con'>
                            <img src={Logo} alt='Logo' />
                        </div>
                        </Link>
                    </Navbar.Brand>
                </Container>
            </Navbar>
        </>
    )
}

export default Header;
