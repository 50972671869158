import React, { useState } from "react";
import "../Css/Services.css";
import { BsArrowRight } from "react-icons/bs";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import {Grid, Autoplay, Pagination } from "swiper/modules";
import  Fade  from "react-reveal";
import { Slide } from "react-reveal";
import TitleSection from "./TitleSection";
import { NavLink } from "react-router-dom";
import Portfolidata from "../Asserts/Portfolidata";

const Services = () => {
  window.scrollTo(0, 0);
  const [activeIndex, setActiveIndex] = useState(null);

  return (
   < >

<section className="mt-5 "
        style={{
          // backgroundImage:
          //   "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)),url('" +
          //   back +
          //   "')",
          // backgroundRepeat: "no-repeat",
          // backgroundSize: "100% 100%",
          // objectFit: "cover",
          // backgroundAttachment: "fixed",
          // color: "white",
        }}
      >
        <div className="container whatsetsusapartstart" style={{position:'relative'}}>
        <TitleSection
          title="What Sets Us Apart"
          // titlePara="Your Dreams, Our Design: Excellence at Every Turn."
          
        />
         </div>
        {/* <MainBanner
        title="What We Bringe To the Table"
        imgURL={banner}
        para="Spoin eravida gibh sel velit tuctor aliquet. Aenean dyfi iysda asslicikoi tudin lorem quis bibendum auctor"
      /> */}
        <section className="">
          <div style={{position:'relative'}} className="container mb-5  whatsetsusapartend">
            <div className="row">
              <div className="">
                <Slide bottom>
                  <p  className="text-center px-3" style={{textAlign:"justify"}}>
                  {/* At Kreeze Design Studio, we combine creativity, expertise,
                  and a personalized approach to deliver exceptional
                  interior design solutions. Our talented team brings 
                  a wealth of experience, transforming your vision into
                  beautifully curated spaces that reflect your unique 
                  style and needs.We prioritize collaboration, ensuring
                  your ideas are at the forefront of our design process.
                  With a keen eye for detail With a deep understanding of
                  design principles and current trends, we bring innovative 
                  solutions that elevate your space. Our designers are adept 
                  at balancing aesthetics with practicality, ensuring that
                  each element serves a purpose while enhancing the overall
                  beauty of your environment. */}
We merge architectural ingenuity with engineering expertise, offering comprehensive design solutions. Our integrated approach ensures that each project balances form and function, delivering spaces that are both visually striking and structurally sound.
                  </p>
                  
                </Slide>
                
                <div className="main-btn-1">
                  <button className="what-we-bringe-button" id="btn-submit">
                    <span className="what-we-bringe-button-text">
                      View More{" "}
                      <label>
                        {" "}
                        <BsArrowRight />{" "}
                      </label>{" "}
                    </span>
                    <div className="what-we-bringe-button-loader">
                      <div />
                      <div />
                      <div />
                    </div>
                  </button>
                </div>
                {/* <div className='what-main-btn'>
      <Link to="/Project">
      <button className="button" id="btn-submit">
        <span className="button-text">View More</span>
        <div className="button-loader">
          <div />
          <div />
          <div />
        </div>
      </button>
      </Link>
      </div> */}
              </div>
            </div>
          </div>
        </section>
       

      </section>







{/* new design for service page */}
      
            <div className="container" style={{color:'  '}}>
              <h3 className="" style={{fontWeight:'bolder'}}> Architectural Design</h3>
              <div className="row mt-5">
                
                <div className="col-12 col-md-5 p-2">
                  <p style={{textAlign:'justify'}}>
                  At Kreeze Design Studio, our architectural design philosophy centers around creating
spaces that resonate with purpose, aesthetics, and longevity. From conceptual sketches to
detailed execution, 
                  </p>
                </div>
                <div className="col-12 col-md-2"></div>
                <div className="col-12 col-md-5 p-2">
                <p style={{textAlign:'justify'}}>
                we deliver designs that are not only visually compelling but also
responsive to the environment and user needs. Every project is a unique narrative, crafted
to balance form and function while integrating sustainable design principles.
    {/* Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corporis facilis, perferendis officiis et itaque, dolor aperiam totam maxime harum nihil inventore nemo aut labore deleniti voluptatibus architecto sunt mollitia unde?
    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corporis facilis, perferendis officiis et itaque,  */}

                  </p>
                </div>

              </div>

              <div className="mt-5">
              <Fade duration={1500}>
          <Swiper
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            750: {
              slidesPerView:2.5,
            },

            1024: {
              slidesPerView: 3.5,
            },
          }}
            
            loop={true}
spaceBetween={30}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
        
            pagination={{
              clickable: true,
            }}
            modules={[Grid,Pagination,Autoplay]}
            className=" mySwiper"
          >
            {Portfolidata.filter((data)=>data.category[0]==='architecture'||data.category[1]==='architecture').map((datap)=>(
                <SwiperSlide  >
                <NavLink to={datap.slugs}>
                <img src={datap.imagesList[0]} alt="image" style={{height:'300px'}}/>
                
                <li className="mt-2" style={{listStyleType:'square'}}>{datap.title}</li>
                </NavLink>
              </SwiperSlide>
            ))}
            
           
            
          </Swiper>
          </Fade>
              </div>
            </div>


              <div className="container py-5">
              <hr />
              </div>

            <div className="container mt-5" style={{color:'  '}}>
              <h3 className="" style={{fontWeight:'bolder'}}> Space Planning</h3>
              <div className="row mt-5">
                
                <div className="col-12 col-md-5 p-2">
                  <p style={{textAlign:'justify'}}>
                  Precision in space planning is key to shaping environments that flow seamlessly. Our
approach ensures that every square foot is optimized for both utility and experience. By
understanding the nuances of each project—
                  </p>
                </div>
                <div className="col-12 col-md-2"></div>
                <div className="col-12 col-md-5 p-2">
                <p style={{textAlign:'justify'}}>
                whether it’s residential, commercial, or
public—we design spatial layouts that enhance movement, create openness, and foster
interaction, all while maintaining a sense of comfort and efficiency
    {/* Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corporis facilis, perferendis officiis et itaque, dolor aperiam totam maxime harum nihil inventore nemo aut labore deleniti voluptatibus architecto sunt mollitia unde?
    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corporis facilis, perferendis officiis et itaque,  */}

                  </p>
                </div>

              </div>

              <div className="mt-5">
              <Fade duration={1500}>
          <Swiper
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            750: {
              slidesPerView:2.5,
            },

            1024: {
              slidesPerView: 3.5,
            },
          }}
            
            loop={true}
spaceBetween={30}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
        
            pagination={{
              clickable: true,
            }}
            modules={[Grid,Pagination,Autoplay]}
            className=" mySwiper"
          >
            {Portfolidata.filter((data)=>data.category[0]==='interior_design'||data.category[1]==='interior_design').map((datap)=>(
                <SwiperSlide  >
                <NavLink to={datap.slugs}>
                <img src={datap.imagesList[0]} alt="image" style={{height:'300px'}}/>
                
                <li className="mt-2" style={{listStyleType:'square'}}>{datap.title}</li>
                </NavLink>
              </SwiperSlide>
            ))}
            
          </Swiper>
          </Fade>
              </div>
            </div>



            <div className="container py-5">
              <hr />
              </div>





            <div className="container mt-5" style={{color:'  '}}>
              <h3 className="" style={{fontWeight:'bolder'}}> Landscape Design</h3>
              <div className="row mt-5">
                
                <div className="col-12 col-md-5 p-2">
                  <p style={{textAlign:'justify'}}>
                  We approach landscape design as an extension of architecture, where the built
environment meets nature. By blending natural elements with structured spaces, we
create landscapes that not only complement the architectural form but also enhance the
sensory experience.
                  </p>
                </div>
                <div className="col-12 col-md-2"></div>
                <div className="col-12 col-md-5 p-2">
                <p style={{textAlign:'justify'}}>
                Whether it's intimate garden spaces or expansive outdoor areas, our
                landscape designs are thoughtful, sustainable, and imbued with character
    {/* Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corporis facilis, perferendis officiis et itaque, dolor aperiam totam maxime harum nihil inventore nemo aut labore deleniti voluptatibus architecto sunt mollitia unde?
    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corporis facilis, perferendis officiis et itaque,  */}

                  </p>
                </div>

              </div>

              <div className="mt-5">
              <Fade duration={1500}>
          <Swiper
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            750: {
              slidesPerView:2.5,
            },

            1024: {
              slidesPerView: 3.5,
            },
          }}
          loop={true}
            
            
spaceBetween={30}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
        
            pagination={{
              clickable: true,
            }}
            modules={[Grid,Pagination,Autoplay]}
            className=" mySwiper"
          >
            {Portfolidata.filter((data)=>data.category[0]==='landscape'||data.category[1]==='landscape').map((datap)=>(
                <SwiperSlide  >
                <NavLink to={datap.slugs}>
                <img src={datap.imagesList[0]} alt="image" style={{height:'300px'}}/>
                
                <li className="mt-2" style={{listStyleType:'square'}}>{datap.title}</li>
                </NavLink>
              </SwiperSlide>
            ))}
            
          </Swiper>
          </Fade>
              </div>
            </div>




            <div className="container py-5">
              <hr />
              </div>



            <div className="container mt-5" style={{color:'  '}}>
              <h3 className="" style={{fontWeight:'bolder'}}> Façade Design</h3>
              <div className="row mt-5">
                
                <div className="col-12 col-md-5 p-2">
                  <p style={{textAlign:'justify'}}>
                  A building’s façade is its fifirst impression, and we believe it should be bold and expressive.
Our façade designs merge aesthetics with performance, carefully selecting materials,
textures, and forms that not only captivate but also function efficiently. 
                  </p>
                </div>
                <div className="col-12 col-md-2"></div>
                <div className="col-12 col-md-5 p-2">
                <p style={{textAlign:'justify'}}>
                Whether it’s
optimizing natural light, ensuring energy efficiency, or creating a visual identity, our façade
solutions are crafted to make a lasting impact
    {/* Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corporis facilis, perferendis officiis et itaque, dolor aperiam totam maxime harum nihil inventore nemo aut labore deleniti voluptatibus architecto sunt mollitia unde?
    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corporis facilis, perferendis officiis et itaque,  */}

                  </p>
                </div>

              </div>

              <div className="mt-5">
              <Fade duration={1500}>
          <Swiper
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            750: {
              slidesPerView:2.5,
            },

            1024: {
              slidesPerView: 3.5,
            },
          }}
            
            loop={true}
spaceBetween={30}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
        
            pagination={{
              clickable: true,
            }}
            modules={[Grid,Pagination,Autoplay]}
            className=" mySwiper"
          >
            {Portfolidata.filter((data)=>data.category[0]==='facade'||data.category[1]==='facade').map((datap)=>(
                <SwiperSlide  >
                <NavLink to={datap.slugs}>
                <img src={datap.imagesList[0]} alt="image" style={{height:'300px'}}/>
                
                <li className="mt-2" style={{listStyleType:'square'}}>{datap.title}</li>
                </NavLink>
              </SwiperSlide>
            ))}
            
          </Swiper>
          </Fade>
              </div>
            </div>


            <div className="container py-5">
              <hr />
              </div>


            <div className="container my-5" style={{color:'  '}}>
              <h3 className="" style={{fontWeight:'bolder'}}>Turnkey Solutions</h3>
              <div className="row mt-5">
                
                <div className="col-12 col-md-5 p-2">
                  <p style={{textAlign:'justify'}}>
                  Our turnkey services embody the art of seamless project delivery. We handle every stage—
from initial concept through fifinal handover—ensuring design intent is preserved throughout.
With meticulous project management and a trusted network of contractors,
                  </p>
                </div>
                <div className="col-12 col-md-2"></div>
                <div className="col-12 col-md-5 p-2">
                <p style={{textAlign:'justify'}}>
                we offer an
all-inclusive solution where clients can trust us to deliver results that align with their vision,
on time and within budget, without the hassle of coordination
    {/* Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corporis facilis, perferendis officiis et itaque, dolor aperiam totam maxime harum nihil inventore nemo aut labore deleniti voluptatibus architecto sunt mollitia unde?
    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corporis facilis, perferendis officiis et itaque,  */}

                  </p>
                </div>

              </div>

              <div className="mt-5">
              <Fade duration={1500}>
          <Swiper
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            750: {
              slidesPerView:2.5,
            },

            1024: {
              slidesPerView: 3.5,
            },
          }}
            
            loop={true}
spaceBetween={30}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
        
            pagination={{
              clickable: true,
            }}
            modules={[Grid,Pagination,Autoplay]}
            className=" mySwiper"
          >
            {Portfolidata.filter((data)=>data.category[0]==='planning'||data.category[1]==='planning').map((datap)=>(
                <SwiperSlide  >
                <NavLink to={datap.slugs}>
                <img src={datap.imagesList[0]} alt="image" style={{height:'300px'}}/>
                
                <li className="mt-2" style={{listStyleType:'square'}}>{datap.title}</li>
                </NavLink>
              </SwiperSlide>
            ))}
            
          </Swiper>
          </Fade>
              </div>
            </div>
      
      
      
            <div className="container py-5">
              <hr />
              </div>
      
      



   
   </>
  );
};

export default Services;
