import imgone from '../Imagess/FEATURED/imgone.jpg'
import imgtwo from '../Imagess/FEATURED/imgtwo.jpg'
import imgthree from '../Imagess/FEATURED/imgthree.jpg'
import imgfour from '../Imagess/FEATURED/imgfour.jpg'
import imgfive from '../Imagess/FEATURED/imagefive.jpg'
import imgsix from '../Imagess/FEATURED/imagesix.jpg'

const Featureddata=[
    {
        Title:" Studio Office | Kreeze Design Studio | Vile Parle",
        img:imgone,
        byname:"interiorlover.in",
        type:"Office",
        date:"7 October 2024",
        desc:"             In this 450 sq.ft. architectural studio, every inch has been meticulously designed to balance functionality with an inviting atmosphere, despite its compact size. The design takes inspiration from Scandinavian minimalism but personalizes it with thoughtful choices in materials, lighting, and layout to create a space that feels both intimate and expansive.",
        link:"https://interiorlover.in/index.php/2024/10/07/studio-office-kreeze-design-studio-vile-parle/"
    },
    {
        Title:"The Scandinavian Interior of this Studio is Both Intimate and Expansive | Kreeze Design Studio",
        img:imgtwo,
        byname:"thearchitectsdiary.com",
        type:"Office",
        date:"October 18 2024",
        desc:"Within 450 sqft, every inch of this scandinavian office interior has been meticulously designed to balance functionality with an inviting atmosphere, despite its compact size. The design of this architecture studio takes inspiration from Scandinavian minimalism but personalizes it with thoughtful choices in materials, lighting, and layout to create a space that feels both intimate and expansive.",
        link:"https://thearchitectsdiary.com/the-scandinavian-interior-of-this-studio-is-both-intimate-and-expansive-kreeze-design-studio/?fbclid=PAZXh0bgNhZW0CMTEAAaYmfH-rTMIvFBrf80UzaGuOOMOilu5B45PQKMgvJVEnx3nZpVU665oZ234_aem_LHpfDxg8M9I51FWX9j319g"
    },
    {
        Title:"Awards",
        byname:"kreezedesignstudio",
        img:imgthree,
        type:"Awards",
        date:"1 November 2024",
        desc:"“Grateful beyond words for this incredible recognition! 🏆✨ Winning this award means so much to our team. We pour our hearts into every project, and this honor reminds us why we love what we do. Thank you to everyone who supports us and believes in our vision. Here’s to more creativity and passion ahead! 🙌💡💖",
        link:"https://www.instagram.com/p/DByDtbUIQhm/?img_index=1"
    },
    {
        Title:"#HomePublication Nestled in the coastal charm of Alibaug",
        img:imgfour,
        byname:"homepublication",
        type:"villa",
        date:"October 14 2024",
        desc:" this stunning villa by @KreezeDesignStudio combines modern elegance with cultural storytelling. From the moment you step inside, the villa greets you with its carefully curated spaces that flow seamlessly, balancing sleek contemporary lines with warm, inviting nooks. The ground floor’s expansive living area features a bespoke credenza and wall panels that pay homage to Alibaug’s local culture, instantly connecting the home to its surroundings.",
        link:"https://www.instagram.com/p/DBGbjyls63h/"
    },
    {
        Title:"Arabella by @kreezedesignstudio",
        img:imgfive,
        byname:"designperspective_",
        type:"villa",
        date:"November 15 2024",
        desc:" Arabella by @kreezedesignstudio this villa combines sleek modern design with Alibaug’s local charm, creating a luxurious yet cozy retreat. Upon entering, you're welcomed by expansive spaces that blend contemporary lines with inviting nooks. The ground floor features a custom credenza and wall panels inspired by local culture, while the open kitchen’s teak accents add natural warmth.",
        link:"https://www.instagram.com/p/DCYZlioPHj7/"
    },
    {
        Title:"The Scandinavian Interior of this Studio is Both Intimate and Expansive | @kreezedesignstudio",
        img:imgsix,
        byname:"thearchitectsdiaryin",
        type:"Office",
        date:"November 26 2024",
        desc:"Fact File Designed by: Kreeze Design Studio Project Type: Office Interior Design Project Name: Kreeze Design Studio Location: Vile Parle East, Mumbai Year Built: 2021 Duration of the project: 3 Months Project Size: 450 Sq.ft Principal Architect: Ar. Kaiwalya Chitnis & Ar. Priyal Pandya",
        link:"https://www.instagram.com/p/DC1lN3-i96A/"
    },
]
export default Featureddata