import React from 'react'
import { VscArrowRight } from "react-icons/vsc";
import Featureddata from '../Asserts/Featureddata';

const Featured = () => {
   window.scrollTo(0, 0);
    
  return (
    <>
    {Featureddata.map((data)=>(
    <div className='container px-5 my-5'>
        <div className='row'>
            <div className='col-12 col-md-1'></div>
            <div className='col-12 col-md-10'>
            <img src={data.img} alt='img' 
             style={{
                minHeight:'400px',
                maxHeight:'500px',
                objectFit:'cover',
                objectPosition:'center'
             }}/>
             <h5 style={{fontSize:'x-large'}} className=' mt-4 display-6'>
             {data.Title}


             </h5>
             <p style={{fontSize:'medium'}} className='lead mt-2'>
                by {data.byname} / {data.type} / {data.date}
             </p>


             <p style={{fontSize:'medium'}} className=' mt-3 lead'>
                {data.desc}
             </p>

             <a href={data.link} target='_blank'><h5 style={{fontSize:'medium',fontWeight:"bold"}} className=' mt-4 '>
Read More    <VscArrowRight />
</h5>
</a>

<hr className='mt-5'/>
            </div>
            <div className='col-12 col-md-1'></div>
        </div>
             

    </div>
    ))}
    </>
  )
}

export default Featured
