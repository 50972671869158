import React, { useEffect, useState } from "react";
// import Typed from 'typed.js';
import { GoArrowUpRight } from "react-icons/go";
import { Link } from "react-router-dom";
import "../Css/GetInTouch.css";
import $ from "jquery";

import { message } from "antd";
const GetInTouch = ({ img }) => {
  // useEffect(() => {
  //   const options = {
  //     strings: ["", "Connect", "Reach out", "Share ideas", "Collaborate", "Let's chat"],
  //     typeSpeed: 100,
  //     backSpeed: 40,
  //     loop: true,
  //   };

  //   const typing = new Typed(".text-main", options);

  //   return () => {
  //     typing.destroy();
  //   };
  // }, []);

  const [name, setName] = useState("");
  const [contact, setContact] = useState("");

  // console.log(name,email,contact,comment)
  const submitForm = (event, values) => {
    event.preventDefault();
    if (contact.length !== 10) {
      message.error("Mobile Number Must Be Ten Digits");
    } else {
      // console.log("value", values)

      var body =
        '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#821f40">Kreeze Design Studio</h2><p>Hello</p><p>Thank you for your interest in comment</p><p>Please check your comment which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left;color:#821f40"><strong>Name:</strong></td><td style="text-align:left">' +
        name +
        '</td></tr><tr><td style="text-align:left;color:#821f40"><strong>Email Id:</strong></td><td style="text-align:left">' +
        ""
        +'</td></tr><tr><td style="text-align:left;color:#821f40"><strong>Contact No:</strong></td><td style="text-align:left">' +
        contact +
        '</td></tr><tr><td style="text-align:left;color:#821f40"><strong>Message:</strong></td><td style="text-align:left">' +
        ""
        +'</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at Kreeze Design Studio</p></div></body></html>';

      $.post(
        "https://skdm.in/server/v1/send_lead_mail.php",
        {
          toEmail: "info@kreeze.design",
          fromEmail: "skdmlead@gmail.com",
          bccMail: "skdmlead@gmail.com",
          mailSubject: "New Customer Lead",
          mailBody: body,
          accountName: "jutiepie",
          accountLeadSource: "",
          accountLeadName: name,
          accountLeadEmail: "",
          accountLeadPhone: contact,
        },

        function (dataa, status) {
          console.log("data :" + dataa);
          console.log("name:" + values);
        }
      );

      message.success(
        "Your Form has Submitted Our team will contact with You  soon."
      );
      // form.resetFields();
      // values.preventDefault();
      // return false;
      setName("");
      setContact("");
    }
  };

  return (
    <>
      <section className="">
        {/* <div className="container">
        <div className="about-arrow"> 
              <Link to='/contact-us'>
              <h5 className="all-arrow"> Conatct Us <GoArrowUpRight /> <p>  <hr /></p> </h5>

              </Link>
               
            </div>
          <div>
            <h2 className="section-title">Get in Touch</h2>
          </div>
          <div className="mb-4">
            <h2 className="title-subtile">
              {" "}
              Building Relationships, One Conversation at a Time

            </h2>
          </div>
        
        </div> */}
        <div className="service-get-in-touch">
          <div className="" style={{ paddingBottom: "31px" }}>
            {/* <h2 className="text-white service-get-in-touch-title">Get In Touch </h2> */}
            {/* <p className="text-white fs-4">Do not hesitate to say <label className="text-main"></label>  </p> */}
            {/* <a href='tel:9833985889'>
              <button className="btn service-get-in-touch-btn">
                Let's Talk <GoArrowUpRight />
              </button>
            </a> */}
            <div className="container">
              <div className="row pt-5">
                <div className="col-4 col-md-4 col-lg-1 col-xlg-1 col-xxlg-1"></div>
                <div className="col-8 col-md-8 col-lg-3 col-xlg-3 col-xxlg-3">
                  <ul>
                    <li className="mt-4" style={{ color: "white" }}>
                      Contact Us
                    </li>
                  </ul>
                  <hr style={{ color: "white", border: "1px solid white" }} />
                </div>
                <div className="col-12 col-md-12 col-lg-8 col-xlg-8 col-xxlg-8">
                  <h2 className="mt-5" style={{ color: "white" }}>
                    LET'S DISCUSS YOUR PROJECT
                  </h2>
                  <br />
                  <p style={{ color: "white" }}>
                    Fill out the form and Our Manager Will contact you for
                    consultation
                  </p>

                  <form onSubmit={submitForm}>
                    <input
                      className="px-2 mx-2 inputone"
                      placeholder="Full Name *"
                      type="text"
                      required=""
                      onChange={(e)=>{setName(e.target.value)}}
                      value={name}
                    />

                    <input
                      className="px-2 mx-2 inputtwo"
                      placeholder="Phone Number *"
                      type="text"
                      required=""
                      onChange={(e)=>{setContact(e.target.value)}}
                      value={contact}
                    />
                    <br className="d-none  d-md-block" />
                    <br className="d-none  d-md-block" />

                    <input
                      className="btn mx-2 inputthree"
                      type="submit"
                      value="Submit"
                    />
                    <br />
                    <br />
                    {/* <div className="form-check ">
                  <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                  <label className="form-check-label" style={{color:'white'}} for="flexCheckDefault">
                    I agree with the <u style={{color:'white'}}>privacy policy </u>
                  </label>
                </div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GetInTouch;
