import React, { useEffect, useLayoutEffect, useState } from "react";
import "../Css/PortfolioGallery.css";
import Portfolidata from "../Asserts/Portfolidata";
import PorfolioParticularGallery from "./PorfolioParticularGallery";
import { Image } from "antd";
import { Link, NavLink, useLocation } from "react-router-dom";

const PortfolioGallery = ({ imagess }) => {
  
  window.scrollTo(0, 0);
  const [perticularPorfolioData, setPerticularPorfolioData] = useState([]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  const path = useLocation();
  const search = path.pathname;
  const getprojectObject = Portfolidata.filter((v) => v.slugs === search);
  const related = Portfolidata.filter((v) => v.slugs !== search);
  const uniqueCategories = [...new Set(related.map((val) => val.category))];
  return (
    <>
      <section>
        <div className="container mt-5">
          {/* <div className="row">
          <div style={{margin:'auto'}} className="col-12 col-lg-7 order-lg-2 ">
            <PorfolioParticularGallery projectObj={getprojectObject.slugs} />

            </div>
            <div className="col-12 col-lg-5 order-lg-1">
            {getprojectObject.map((value) => {
            return (
              <div key={value.slugs}>
                <h2 className="mt-5">{value.title}</h2>
                <div className="row ribbon-row">
                  <div className="col-md-3">
                    <div className="left-line">
                      <label className="left-text-ribbon">Status</label>
                      <div className="ribbon-tittle">
                        <span className="ribbon-city-name">Completed</span>
                      </div>
                    </div> */}
                    {/* Additional details... */}
                  {/* </div>
                  <div className="
                  
                  ">
                    <div className="col-right-text" style={{textAlign:'justify'}}>
                      <p>{value.desc}</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
            </div>
            

          </div>
          
        </div>
      </section>

      <section>
        <div className="container mb-4">
          <div className="releted-project">
            <div className="all-projects">
              <Link to="/portfolio/all/all" style={{ color: "#1b1b1b" }}>
                <label className="all-project-text">All Projects</label>
              </Link>
            </div>
            <div>
              {getprojectObject.map((value)=>{
                return(
                  <>
             <NavLink to={`/portfolio/${value.category[0]}/all`}> <span className="releted-text">Related Projects</span></NavLink>
             </>
            )
              })}
              <table
                className="releted-project-table"
                style={{ width: "100%", position: "relative" }}
              >
                <tbody>
                {Portfolidata.map((category, index) => (
      <tr className="table-rows mb-5" key={index}>
        
        <td>
          <span style={{ color: "#1b1b1b" }}>
           
          </span>
        </td>
        <td></td>
      </tr>
    ))}
                </tbody>
              </table>
            </div>
          </div> */}


          {/* New Design For Project Particular Start */}


          {getprojectObject.map((value) => {
            return (
              <>
              <h5 className="lead px-3 pt-5" style={{fontSize:'31px'}}>{value.title}</h5>
              <p className="lead px-3" style={{color:'gray'}}>
                {value.location}
              </p>
              <div className="row">
              <div className="col-12 col-lg-4 px-2 mt-4 order-lg-1">
                  <p className="lead text-end" style={{color:'gray'}}>
                    {value.area}
                    <br/>
                    Status : {value.status}
                  </p>
                </div>

                <div className="col-12 col-lg-8 px-2 my-4 mb-5">
                  <p className="lead px-3" style={{textAlign:'justify'}}>
                {value.desc}
                </p>
                </div>
                
              </div>
              {value.imagesList.map((imgdata)=>(
              <div className="container my-5">
                <img src={imgdata} style={{
                  maxWidth:'800px',margin:'auto'
                }} alt="img"/>
              </div>
              ))}

                <div className="container my-5" style={{display:'flex',justifyContent:'space-between'}}>
                <div className="all-projects">
              <Link to="/portfolio/all/all" style={{ color: "#1b1b1b" }}>
                <label className="all-project-text">All Projects</label>
              </Link>
            </div>
            <div>
              {getprojectObject.map((value)=>{
                return(
                  <>
             <NavLink to={`/portfolio/${value.category[0]}/all`}> <span className="releted-text">Related Projects</span></NavLink>
             </>
            )
              })}
              
            </div>
                </div>

              </>
            )})}



          {/* New Design For Project Particular End */}

        </div>
      </section>
    </>
  );
};

export default PortfolioGallery;
