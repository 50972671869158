import React from "react";
import {} from "../Css/NewBanner.css";

const NewBanner = ({ title, imgURL, para }) => {
  return (
    <>
      <div className="main-card-newBanner mb-5">
        <div className="container">
          <div className="titleCard-newBanner d-flex align-items-center justify-content-left py-3">
            <h1 className="m=0"> {title} </h1>
            <p>{para}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewBanner;
