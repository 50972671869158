import React from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import "../Css/Project.css";
import Portfoliodata from "../Asserts/Portfolidata"; // Corrected path for the portfolio data
import NewBanner from "./NewBanner";
import { Divider } from 'antd';
const Project = () => {
  window.scrollTo(0, 0);
  const { main, sub } = useParams(); // Get main and sub from URL parameters
  const navigate = useNavigate();

  const menuLink = [
    {
      title: "Show All",
      link: "all",
      sub: [],
    },
    {
      title: "ARCHITECTURE",
      link: "architecture",
      sub: [
        { title: "All", link: "all" },
        // { title: "Planning", link: "planning" }, // Added Planning subcategory
        // { title: "Buildings", link: "buildings" },
        // { title: "Villas", link: "villas" },
        // { title: "Landscape", link: "landscape" },
        // { title: "Facade", link: "facade" },
        { title: "Residential", link: "residential" }, // Added Planning subcategory
        { title: "Cultural", link: "cultural" },
        { title: "Civic", link: "civic" },
        { title: "Hospitality", link: "hospitality" },
        
      ],
    },
    {
      title: "INTERIOR DESIGN",
      link: "interior_design",
      sub: [
        { title: "All", link: "all" },
        // { title: "Villas", link: "villas" },
        // { title: "Apartments", link: "apartments" },
        // { title: "Offices", link: "offices" },
        { title: "Residential", link: "residential" }, // Added Planning subcategory
        { title: "Commercial", link: "commercial" },
        { title: "Hospitality", link: "hospitalityi" },
        { title: "Institutional", link: "institutional" },
        { title: "Amenities", link: "amenities" },

      ],
    },
    {
      title: "LANDSCAPE",
      link: "landscape",
      sub: [
        { 
          // title: "All",
           link: "all" },
        // { title: "Commercial", link: "commercial" },
        // { title: "Cultural", link: "cultural" },
        // { title: "Housing", link: "housing" },
        // { title: "Public", link: "public" },
      ],
    },

    {
      title: "PLANNING",
      link: "planning",
      sub: [
        // { 
          // title: "All",
          //  link: "all" },
        // { title: "Commercial", link: "commercial" },
        // { title: "Cultural", link: "cultural" },
        // { title: "Housing", link: "housing" },
        // { title: "Public", link: "public" },
      ],
    },

    {
      title: "FACADE",
      link: "facade",
      sub: [
        // { 
          // title: "All",
          //  link: "all" },
        // { title: "Commercial", link: "commercial" },
        // { title: "Cultural", link: "cultural" },
        // { title: "Housing", link: "housing" },
        // { title: "Public", link: "public" },
      ],
    },
  ];

  // Function to handle navigation
  const handleNavigate = (link, subLink = "all") => {
    navigate(`/portfolio/${link}/${subLink}`);
  };
  
    Portfoliodata.map((value)=>{
      console.log(value)
    })
    
  

  return (
    <>
      <NewBanner title="Portfolio" />
      <section>
        <div className="second-section">
          <div className="mb-5">
            {/* Menu Links */}
            <div className="design-list">
              {menuLink.map((val, index) => (
                <li
                  key={index}
                  className={main === val.link ? "active" : ""}
                  onClick={() => handleNavigate(val.link)}
                >
                  {val.title}
                </li>
              ))}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}> {/* Center using flexbox */}
      <Divider style={{ width: '100px' }} />
    </div>
            {/* Sub-menu Links */}
            <div className="list-inside-list">
              {menuLink
                .find((val) => val.link === main)
                ?.sub.map((val, index) => (
                  <li
                    key={index}
                    className={sub === val.link ? "active" : ""}
                    onClick={() => handleNavigate(main, val.link)}
                  >
                    {val.title}
                  </li>
                ))}
            </div>
          </div>

          {/* Project Grid */}
          <div className="grid-section mb-5">
            <div className="container">
              <div className="row">
                {Portfoliodata.filter(
                  
                  (value) =>
                    (main === "all" || value.category[0] === main) &&
                    (sub === "all" || value.subCategory[0] === sub) || ( value.subCategory[1] === sub) || (value.subCategory[2]===sub) || (value.subCategory[3]===sub )
                    || (main === "all" || value.category[1] === main) &&
                    (sub === "all" || value.subCategory[0] === sub) || ( value.subCategory[1] === sub) || (value.subCategory[2]===sub) || (value.subCategory[3]===sub )
                
                  ).map((value, i) => (
                    
                  <div className="col-lg-4 mt-3 col-md-4 col-sm-6 project-main" key={i}>
                    <NavLink to={value.slugs}>
                      <div className="inner-card">
                        <div>
                          <img
                            className="project-card-images"
                            src={value.imagesList[0]} // Display the first image of the project
                            alt="card"
                          />
                        </div>
                        <div className="project-para">
                          <p>
                            <label className="card-title">{value.title}</label>
                            <br />
                          </p>
                        </div>
                      </div>  
                    </NavLink>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Project;
